import { KTSVG } from '../../../helpers'
import { AsideMenuItem } from './AsideMenuItem'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'

export function AsideMenuMain() {
  return (
    <>
      <AsideMenuItem
        to='/'
        icon='/media/icons/duotune/arrows/arr001.svg'
        // title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
        title='Home Page'
        fontIcon='bi-app-indicator'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Menu</span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to='/profile/'
        title='Perfil'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/profile/overview' title='Dados Pessoais' hasBullet={true} />
        <AsideMenuItem to='/profile/team' title='Gerir Equipa' hasBullet={true} />
        <AsideMenuItem to='/profile/password' title='Alterar Password' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to='/event/'
        title='Eventos'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/event/inscriptions' title='As minhas inscrições' hasBullet={true} />
      </AsideMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      <div className='menu-item'>
        <a className='menu-link' href='#' rel='noreferrer'>
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Versão {process.env.REACT_APP_VERSION}</span>
        </a>
      </div>
    </>
  )
}
